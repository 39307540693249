require('./bootstrap');

require('@fortawesome/fontawesome-free/js/all');

import flatpickr from "flatpickr";

flatpickr('.datepicker', {
    weekNumbers: true,
    dateFormat: "Y-m-d",
    wrap: true,
    locale: {
        firstDayOfWeek: 1 // start week on Monday
    }
});

flatpickr(".rangepicker", {
    weekNumbers: true,
    wrap: true,
    dateFormat: "Y-m-d",
    mode: "range",
    locale: {
        firstDayOfWeek: 1 // start week on Monday
    }
});

$('[data-toggle="popover"]').popover({
    trigger: 'hover',
});

// don't close dropdown menu when selecting filter options
$(document).on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
});
